import React, {useContext, useEffect, useState} from "react";

import Typography from "@mui/material/Typography";
import LinearProgress from "@mui/material/LinearProgress";

import styled from "@emotion/styled";
import {stepContext} from "../provider/StepProvider";
import logo from "../../assets/logo-green-white.svg";
import {creatorContext} from "../provider/CreatorProvider";

const StyledHeaderLogo = styled.img`
  height: 0.9em;
  margin-bottom: 0.15em;
  vertical-align: middle;
`;

const StyledHeader = styled.header`
  padding-bottom: 2rem;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 2rem;
  margin: auto;
  max-width: 800px;
`;

const StyledHeadline = styled(Typography)`
  word-break: break-word;
`;
function Header(props) {
  const {creator} = useContext(creatorContext);

  if (!creator) {
    return <LinearProgress />;
  }

  return (
    <>
      <StyledHeader>
        <StyledHeadline variant="h4" component="h1">
          <b>{creator.socialname}</b>
        </StyledHeadline>
        <Typography variant="h4" component="h1">
          Anfrage
        </Typography>

        <Typography>
          <StyledHeaderLogo src={logo} />
          <b> - so funktionieren Anfragen heute.</b>
        </Typography>
        <Typography gutterBottom></Typography>
      </StyledHeader>
    </>
  );
}

export default Header;
