export function mainBrandData(steps, answers) {
  const results = steps
    .map((stepId) => {
      const step = stepDefinitions[stepId];
      return brandStep(answers, step);
    })
    .flat(9)
    .filter((e) => e);

  //from array to object
  let data = {};
  results.forEach((step) => {
    data[step.brandAttribute] = step.value;
  });
  return data;
}

function brandStep(answers, step) {
  const fields = step.fields;
  const entry = fields?.questions;

  if (!entry || entry.length === 0) {
    // when not question, there could not be brand attributes.
    return null;
  }

  return entry
    .map((item) => {
      const questionEntryId = item.sys.id;
      if (!(item.fields.brandAttribute?.length > 0)) {
        return null;
      }
      let answer = answers[questionEntryId];
      if (!answer) {
        return null;
      }
      answer.id = questionEntryId;
      answer.brandAttribute = item.fields.brandAttribute;
      return answer;
    })
    .filter((e) => e);
}
