import React, {useContext, useEffect, useState} from "react";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {stepContext} from "../../provider/StepProvider";
import {StyledBox} from "./FormAtoms";

export function FormBack() {
  const {prevStep} = useContext(stepContext);
  return (
    <StyledBox>
      <Button
        variant="outlined"
        color="secondary"
        fullWidth
        startIcon={<ArrowBackIcon />}
        onClick={() => prevStep()}>
        Doch lieber noch einmal einen Schritt zurück
      </Button>
      <Divider />
    </StyledBox>
  );
}
