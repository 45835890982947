import React, {useContext, useEffect, useState} from "react";
import {StyledTitle, StyledBox} from "./FormAtoms";

export function FormTitle(props) {
  const {entryId, fields} = props;
  return (
    <StyledBox>
      <StyledTitle>{fields}</StyledTitle>
    </StyledBox>
  );
}
