import Link from "@mui/material/Link";

import {documentToReactComponents} from "@contentful/rich-text-react-renderer";
import {BLOCKS, MARKS, INLINES} from "@contentful/rich-text-types";

const options = {
  renderMark: {},
  renderNode: {
    [INLINES.HYPERLINK]: ({data}, children) => (
      <Link
        href={data.uri}
        target={`${data.uri.startsWith(location.origin) ? "_self" : "_blank"}`}
        rel={`${
          data.uri.startsWith(location.origin) ? "" : "noopener noreferrer"
        }`}>
        {children}
      </Link>
    ),
  },
};

export function renderRichText(input) {
  return documentToReactComponents(input, options);
}
