import React, {useContext, useEffect, useState} from "react";
import Typography from "@mui/material/Typography";
import FormGroup from "@mui/material/FormGroup";
import {StyledBox, StyledLoadingButton} from "./FormAtoms";

export function FormSubmit(props) {
  const {entryId, fields, submitInquiry, loading, validateData} = props;

  function onClick() {
    submitInquiry();
  }

  return (
    <StyledBox>
      <FormGroup>
        <StyledLoadingButton
          onClick={onClick}
          variant="contained"
          color="secondary"
          fullWidth
          loading={loading}
          disabled={!validateData()}>
          {fields.title}
        </StyledLoadingButton>
        <Typography variant="caption">{fields.caption}</Typography>
      </FormGroup>
    </StyledBox>
  );
}
