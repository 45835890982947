import React, {useContext, useEffect, useState} from "react";
import {useTheme} from "@mui/material/styles";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import {answerContext} from "../../provider/AnswerProvider";
import {StyledBox} from "./FormAtoms";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(option, options, theme) {
  return {
    fontWeight:
      options.indexOf(option) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export function FormMultiselect(props) {
  const theme = useTheme();
  const {entryId, fields} = props;
  const {answers, handleAnswer, isQuestionValid} = useContext(answerContext);

  function handleChange(event) {
    const {
      target: {value},
    } = event;
    handleAnswer(
      entryId,
      // On autofill we get a the stringified value.
      // https://mui.com/components/selects/#chip
      typeof value === "string" ? value.split(",") : value,
      fields.title,
      "formMultiselect",
      fields?.inquiryCategory?.fields?.title
    );
  }

  const value = answers[entryId]?.value || [];

  const error =
    answers[entryId]?.value &&
    !isQuestionValid(answers[entryId]?.value, "formMultiselect");

  const options = fields.options.split("\n");

  return (
    <StyledBox>
      <FormControl required={true} error={error} fullWidth>
        <InputLabel>{fields.title}</InputLabel>
        <Select
          multiple
          value={value}
          onChange={handleChange}
          input={
            <OutlinedInput id="select-multiple-chip" label={fields.title} />
          }
          renderValue={(selected) => (
            <Box sx={{display: "flex", flexWrap: "wrap", gap: 0.5}}>
              {selected.map((value) => (
                <Chip key={value} label={value} />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}>
          {options.map((option) => (
            <MenuItem
              key={option}
              value={option}
              style={getStyles(option, value, theme)}>
              {option}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>{fields.caption} </FormHelperText>
      </FormControl>
    </StyledBox>
  );
}
