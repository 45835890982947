export function mainValidateData(steps, answers, stepDefinitions) {
  const results = steps.map((stepId) => {
    const step = stepDefinitions[stepId];
    return validateStep(answers, step);
  });

  return results.filter((e) => e !== true).length === 0;
}

function validateStep(answers, step) {
  const fields = step.fields;

  const entry = fields?.questions;
  if (!entry || entry.length === 0) {
    // per default true, when not a question. because: only question can be incorectly validated.
    return true;
  }
  const validatedStep = entry.map((item) => {
    const questionId = item.sys.id;
    const questionContentModel = item.sys.contentType.sys.id;
    const questionValidation = item.fields.validation;
    return isQuestionValid(
      answers[questionId]?.value,
      questionContentModel,
      questionValidation
    );
  });
  return validatedStep.filter((e) => e !== true).length === 0;
}

export function isQuestionValid(value, contentModel, validation) {
  switch (contentModel) {
    case "formCheckbox":
      return value === true;

    case "formMultiselect": //array
    case "formRadiobuttons": //text
      return value && value.length > 0; //makes both sense.

    case "formCurrency":
      if (isNaN(value)) {
        return false;
      }

      if (validation > 0 && value < validation) {
        return false;
      }
      return true;

    case "formTextarea":
    case "formText":
      if (!validation) {
        return true;
      }

      switch (validation) {
        case "2chars":
          if (!value) {
            return !validation;
          }

          return value.length > 1;

        case "5chars":
          if (!value) {
            return !validation;
          }

          return value.length > 4;

        case "10chars":
          if (!value) {
            return !validation;
          }

          return value.length > 9;

        case "url":
          if (!value) {
            return !validation;
          }

          const expression = /^https?:\/\/([\w\d\-]+\.)+\w{2,}(\/.+)?$/gi;
          const regex = new RegExp(expression);

          if (!value.match(regex)) {
            return false;
          }
          return true;

        case "urlOptional":
          console.log("urloptional", value);

          if (!value || value.length === 0) {
            return true;
          }
          const expressionOptional = /^https?:\/\/([\w\d\-]+\.)+\w{2,}(\/.+)?$/gi;
          const regexOptional = new RegExp(expressionOptional);

          if (!value.match(regexOptional)) {
            console.log('false');

            return false;
          }
          console.log('true');
          return true;

        case "email": // https://stackoverflow.com/a/57741813
          if (!value) {
            return !validation;
          }

          const emailRegex =
            /^[-!#$%&'*+\/0-9=?A-Z^_a-z{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;
          if (!value) {
            return false;
          }

          if (value.length > 254) {
            return false;
          }

          var valid = emailRegex.test(value);
          if (!valid) {
            return false;
          }

          // Further checking of some things regex can't handle
          var parts = value.split("@");
          if (parts[0].length > 64) {
            return false;
          }

          var domainParts = parts[1].split(".");
          if (
            domainParts.some(function (part) {
              return part.length > 63;
            })
          ) {
            return false;
          }

          return true;

        default:
          return true;
      }

    default:
      return true;
  }
}
