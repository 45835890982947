import React, {useContext, useEffect, useState} from "react";
import LinearProgress from "@mui/material/LinearProgress";
import {withBasicProviders} from "../provider/withBasicProviders";
import CreatorProvider, {creatorContext} from "../provider/CreatorProvider";
import Header from "../components/Header";
import Footer from "../components/Footer";
import {useParams} from "react-router";
import StaticAlert from "../components/StaticAlert";
import useFetch from "use-http";
import {API} from "../config";

function Static(props) {
  console.log("static");

  const {creator} = useContext(creatorContext);
  const {id, hash, action} = useParams();
  const [result, setResult] = useState(null);

  if (!action) {
    action = props.action;
  }

  useEffect(() => {
    if (!creator) {
      return;
    }

    document.title = `fillter.me ${creator.socialname} Anfrage`;
  }, [creator]);

  const {post, response, loading, error} = useFetch(
    `${API}/projects/${id}/${action}`
  );

  async function clickHandler() {
    const newResult = await post();

    setResult(newResult);
  }

  if (!creator) {
    return <LinearProgress />;
  }

  return (
    <>
      <Header />
      <StaticAlert
        action={action}
        onClick={clickHandler}
        error={error}
        loading={loading}
        result={result}
      />
      <Footer />
    </>
  );
}

export default withBasicProviders(CreatorProvider)(Static);
