import React, {useContext, useEffect, useState} from "react";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import {StyledBox} from "./FormAtoms";

export function FormCurrency(props) {
  const {entryId, fields, answers, handleAnswer, isQuestionValid} = props;

  const value = answers[entryId]?.value || "";
  return (
    <StyledBox>
      <TextField
        onChange={(e) =>
          handleAnswer(
            entryId,
            e.target.value,
            fields.title,
            "formCurrency",
            fields?.inquiryCategory?.fields?.title
          )
        }
        onBlur={() =>
          handleAnswer(
            entryId,
            value.trim(),
            fields.title,
            "formText",
            fields?.inquiryCategory?.fields?.title
          )
        }
        name={entryId}
        helperText={fields.caption}
        value={value}
        error={
          answers[entryId]?.value &&
          !isQuestionValid(
            answers[entryId]?.value,
            "formCurrency",
            fields.validation
          )
        }
        label={fields.title}
        fullWidth
        InputProps={{
          startAdornment: <InputAdornment position="start">€</InputAdornment>,
        }}
      />
    </StyledBox>
  );
}
