import React, {useContext, useEffect, useState} from "react";
import {stepContext} from "../../provider/StepProvider";
import {answerContext} from "../../provider/AnswerProvider";

import {StyledButton} from "./FormAtoms";

export function ChoiceButton(props) {
  const {stepId, choiceId, fields} = props;
  const {nextStep} = useContext(stepContext);
  const {answers, handleAnswer, validateData} = useContext(answerContext);

  function onClick() {
    handleAnswer(
      stepId,
      choiceId,
      fields.title,
      "choice",
      fields?.inquiryCategory?.fields?.title
    );
    nextStep(choiceId);
  }
  return (
    <StyledButton
      key={`form-choice-${choiceId}`}
      onClick={onClick}
      variant="contained"
      color="primary"
      fullWidth
      disabled={!validateData()}>
      {fields.title}
    </StyledButton>
  );
}
