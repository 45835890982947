// go through every step, because it is possible that there are fetched steps or answers that were later discarded.
export function mainAliasData(steps, answers, stepDefinitions) {
  // go through the steps to make sure, that we only t ke steps that are really taken.
  // stepDefinitions and answers may be cached / old.
  const aliasSteps = steps
    .map((stepId) => {
      const step = stepDefinitions[stepId];
      const fields = step.fields;

      return Object.keys(fields).map((k) => {
        const v = fields[k];
        return aliasFrame(stepId, k, v, answers);
      });
    })
    .flat(9)
    .filter((e) => e);

  let data = {};
  aliasSteps.forEach((step) => {
    data[step.key] = step.value;
  });
  return data;
}

function aliasFrame(stepId, contentModel, entry, answers) {
  switch (contentModel) {
    // go through every element of the questions to build the answer.
    case "questions":
      if (entry.length === 0) {
        break;
      }

      return entry
        .filter((item) => item.fields.inInquiry)
        .map((item) => {
          const alias = item.fields?.alias;
          if (!alias) {
            return null;
          }
          const entryId = item.sys.id;
          let answer = {...answers[entryId]};
          answer.value = `${answer.value}`;

          return {
            key: alias,
            value: answer.value,
          };
        })
        .filter((e) => e);

    // check if a choice was clicked, and if yes, build the answer.
    case "choices":
      let answer = {...answers[stepId]};
      const choiceId = answer.value;

      // step of the current frame.
      if (!stepDefinitions[choiceId]) {
        //not filled out yet?
        return null;
      }

      const stepDefinition = stepDefinitions[choiceId];
      const alias = stepDefinition.fields?.alias;

      if (!alias) {
        return null;
      }
      return {
        key: alias,
        value: answer.title,
      };
    default:
      return null;
  }
}
