import React, {useState, useEffect, useContext} from "react";
import {answerContext} from "./AnswerProvider";
import useFetch from "use-http";
import {API} from "../config";
import {creatorContext} from "./CreatorProvider";

function InquiryProvider(props) {
  const {creator} = useContext(creatorContext);
  const {inquiryData, brandData, relevanceData, aliasData} =
    useContext(answerContext);
  const [submission, setSubmission] = useState(null);
  const {post, response, loading, error} = useFetch(API);

  async function submitInquiry() {
    const transformed = {
      creatorname: creator.username,
      brand: brandData(),
      alias: aliasData(),
      relevance: relevanceData(),
      inquiry: inquiryData(),
    };

    // console.log("transformed", transformed);

    const newResult = await post("/projects", transformed);
    setSubmission(newResult);
  }

  return (
    <inquiryContext.Provider
      value={{
        submitInquiry,
        response,
        loading,
        error,
        submission,
      }}>
      {props.children}
    </inquiryContext.Provider>
  );
}

export default InquiryProvider;
export const inquiryContext = React.createContext();
