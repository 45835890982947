import React, {useState, useEffect, useContext} from "react";

import {stepContext} from "../StepProvider";
import {mainAliasData} from "./alias";
import {mainBrandData} from "./brand";
import {mainInquiryData} from "./inquiry";
import {mainRelevanceData} from "./relevance";
import {mainValidateData, isQuestionValid} from "./validate";

function AnswerProvider(props) {
  const {steps, stepDefinitions} = useContext(stepContext);
  const [answers, setAnswers] = useState(
    localStorage.getItem("debug") === "1"
      ? JSON.parse(localStorage.getItem("answers"))
      : {}
  );

  function handleAnswer(id, value, title, contentModel, category) {
    let updated = {...answers};
    updated[id] = {value, contentModel, title, category};
    setAnswers(updated);
    //debug
    window.answers = updated;
  }

  // console.log(answers);

  return (
    <answerContext.Provider
      value={{
        answers,
        handleAnswer,
        isQuestionValid,
        validateData: () => mainValidateData(steps, answers, stepDefinitions),
        inquiryData: () => mainInquiryData(steps, answers, stepDefinitions),
        brandData: () => mainBrandData(steps, answers),
        relevanceData: () => mainRelevanceData(steps, answers, stepDefinitions),
        aliasData: () => mainAliasData(steps, answers, stepDefinitions),
      }}>
      {props.children}
    </answerContext.Provider>
  );
}

export default AnswerProvider;
export const answerContext = React.createContext();
