import React, {useContext, useEffect, useState} from "react";
import TextField from "@mui/material/TextField";
import {StyledBox} from "./FormAtoms";

export function FormTextarea(props) {
  const [focus, setFocus] = useState(false);
  const {entryId, fields, answers, handleAnswer, isQuestionValid} = props;

  const value = answers[entryId]?.value || "";
  return (
    <StyledBox>
      <TextField
        onChange={(e) =>
          handleAnswer(
            entryId,
            e.target.value,
            fields.title,
            "formTextarea",
            fields?.inquiryCategory?.fields?.title
          )
        }
        onBlur={() => {
          setFocus(false);
          handleAnswer(
            entryId,
            value.trim(),
            fields.title,
            "formTextarea",
            fields?.inquiryCategory?.fields?.title
          );
        }}
        onFocus={() => setFocus(true)}
        required={!!fields.validation && fields.validation !== "urlOptional"}
        multiline
        rows={4}
        name={entryId}
        inputProps={{maxLength: fields.maxLength}}
        helperText={fields.caption}
        value={value}
        error={
          !focus &&
          answers[entryId]?.value &&
          !isQuestionValid(
            answers[entryId]?.value,
            "formTextarea",
            fields.validation
          )
        }
        label={fields.title}
        fullWidth
      />
    </StyledBox>
  );
}
