import React, {useContext, useEffect, useState} from "react";
import {StyledBox, StyledDescription} from "./FormAtoms";
import {renderRichText} from "../../contentful/render";

export function FormDescription(props) {
  const {entryId, text} = props;
  return (
    <StyledBox>
      <StyledDescription>{renderRichText(text)}</StyledDescription>
    </StyledBox>
  );
}
