import React, {useContext, useEffect, useState} from "react";
import {StyledTitle, StyledBox, StyledDescription} from "./FormAtoms";
import {renderRichText} from "../../contentful/render";

export function FormIntro(props) {
  const {entryId, fields} = props;
  return (
    <StyledBox>
      <StyledTitle>{fields.title}</StyledTitle>
      <StyledDescription>
        {renderRichText(fields.description)}
      </StyledDescription>
    </StyledBox>
  );
}
