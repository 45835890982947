import React, {useContext, useEffect, useState} from "react";
import Typography from "@mui/material/Typography";
import {StyledBox} from "./FormAtoms";

export function RootCaption(props) {
  const {text} = props;
  if (!text) {
    return null;
  }

  return (
    <StyledBox>
      <Typography color="default" variant="caption">
        {text}
      </Typography>
    </StyledBox>
  );
}
