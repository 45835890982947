import React, {useContext, useEffect, useState} from "react";
import {stepContext} from "../../provider/StepProvider";
import {FormBack} from "./FormBack";
import {StyledMain} from "../CommonAtoms";

import {FormTitle} from "./FormTitle";
import {FormDescription} from "./FormDescription";
import {FormText} from "./FormText";
import {FormRadiobuttons} from "./FormRadiobuttons";
import {FormIntro} from "./FormIntro";
import {FormCheckbox} from "./FormCheckbox";
import {FormSubmit} from "./FormSubmit";
import {ChoiceButton} from "./ChoiceButton";
import {RootTitle} from "./RootTitle";
import {FormMultiselect} from "./FormMultiselect.jsx";
import {FormCurrency} from "./FormCurrency";
import {FormTextarea} from "./FormTextarea";
import {RootCaption} from "./RootCaption";
import {FormRelevance} from "./FormRelevance";
import {answerContext} from "../../provider/AnswerProvider";
import {inquiryContext} from "../../provider/InquiryProvider";

function checkIfCurrentStep(steps, fields) {
  const entry = fields.choices || [];
  let isCurrent = true;
  entry.forEach((choice) => {
    if (steps.filter((step) => step === choice.sys.id).length > 0) {
      isCurrent = false;
    }
  });
  return isCurrent;
}

function Form() {
  const {steps, nextStep, prevStep, stepDefinitions} = useContext(stepContext);
  const {submitInquiry, loading} = useContext(inquiryContext);
  const {answers, handleAnswer, isQuestionValid, validateData} =
    useContext(answerContext);

  const renderedSteps = steps.map((stepId) => {
    const step = stepDefinitions[stepId];
    if (!step) {
      console.error(`Step ${stepId} was not fetched.`);
      return;
    }
    const fields = step.fields;
    const isCurrentStep = checkIfCurrentStep(steps, fields);

    const renderable = Object.keys(fields).map((k) => {
      const v = fields[k];
      return renderFrame(stepId, k, v, isCurrentStep);
    });
    return renderable;
  });

  function renderFrame(stepId, frameContentModel, frameEntry, isCurrentStep) {
    switch (frameContentModel) {
      case "title":
        return <RootTitle key={frameContentModel + stepId} text={frameEntry} />;

      case "caption":
        if (!isCurrentStep) {
          break;
        }
        return (
          <RootCaption key={frameContentModel + stepId} text={frameEntry} />
        );

      case "questions":
        if (frameEntry.length === 0) {
          break;
        }
        return frameEntry.map((questionEntry) => {
          const questionContentModel = questionEntry.sys.contentType.sys.id; // e.g. "formText"
          const questionEntryId = questionEntry.sys.id;
          const questionFields = questionEntry.fields;

          const questionProps = {
            steps,
            nextStep,
            prevStep,
            stepDefinitions,
            submitInquiry,
            loading,
            answers,
            stepId,
            handleAnswer,
            isQuestionValid,
            validateData,
            key: `${stepId}-${questionEntryId}`,
            entryId: questionEntryId,
            fields: questionFields,
          };

          switch (questionContentModel) {
            case "title":
              return <FormTitle {...questionProps} />;
            case "description":
              return <FormDescription {...questionProps} />;
            case "formText":
              return <FormText {...questionProps} />;
            case "formRelevance":
              return <FormRelevance {...questionProps} />;
            case "formTextarea":
              return <FormTextarea {...questionProps} />;
            case "formCurrency":
              return <FormCurrency {...questionProps} />;
            case "formRadiobuttons":
              return <FormRadiobuttons {...questionProps} />;
            case "formMultiselect":
              return <FormMultiselect {...questionProps} />;
            case "formIntro":
              return <FormIntro {...questionProps} />;
            case "formCheckbox":
              return <FormCheckbox {...questionProps} />;
            case "formSubmit":
              return <FormSubmit {...questionProps} />;
          }
        });

      case "choices":
        if (!isCurrentStep) {
          break;
        }

        return frameEntry.map((choiceEntry) => {
          const choiceEntryId = choiceEntry.sys.id;
          const choiceFields = choiceEntry.fields;

          return (
            <ChoiceButton
              key={choiceEntryId}
              stepId={stepId}
              choiceId={choiceEntryId}
              fields={choiceFields}
            />
          );
        });
    }
  }

  return (
    <>
      <StyledMain>
        <form>
          {renderedSteps}
          {steps.length > 1 && <FormBack />}
        </form>
      </StyledMain>
    </>
  );
}

export default Form;
