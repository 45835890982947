import React, {
  useState,
  useEffect,
  useMemo,
  useContext,
  useLayoutEffect,
} from "react";

import {createRoot} from "react-dom/client";

import {Route, BrowserRouter, Routes} from "react-router-dom";

import Home from "./pages/Home";
import User from "./pages/User";

import {StylesProvider} from "@mui/styles";
import {ThemeProvider, createTheme} from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Static from "./pages/Static";

function App() {
  const theme = useMemo(
    () =>
      createTheme({
        typography: {},
        palette: {
          primary: {main: "#0096B8"},
          secondary: {main: "#81BE49"},
          mode: "dark",
        },
        components: {
          MuiCssBaseline: {
            styleOverrides: {
              body: {
                background: "#0E262E",
              },
              "*[data-com-onepassword-filled]": {
                color: "white !important",
              },
            },
          },
        },
      }),
    []
  );

  return (
    <StylesProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <BrowserRouter>
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route
              exact
              path="/:creatorname/projects/:id/:action"
              element={<Static />}
            />
            <Route exact path="/:creatorname" element={<User />} />
            <Route
              exact
              path="/:creatorname/debug"
              element={<User debug={true} />}
            />
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </StylesProvider>
  );
}

const container = document.getElementById("app");
const root = createRoot(container);
root.render(<App />);
