// go through every step, because it is possible that there are fetched steps or answers that were later discarded.
export function mainRelevanceData(steps, answers, stepDefinitions) {
  // go through the steps to make sure, that we only t ke steps that are really taken.
  // stepDefinitions and answers may be cached / old.
  return steps.reduce((a, stepId) => {
    const step = stepDefinitions[stepId];
    const fields = step.fields;

    const frameRelevance = Object.keys(fields).reduce((a, k) => {
      const v = fields[k];
      const frameField = relevanceFrame(stepId, k, v, answers);

      return a + frameField;
    }, 0);
    return a + frameRelevance;
  }, 0);
}

function relevanceFrame(stepId, contentModel, entry, answers) {
  switch (contentModel) {
    // go through every element of the questions to build the answer.
    case "questions":
      if (entry.length === 0) {
        break;
      }
      return entry.reduce((a, item) => {
        const itemModel = item.sys.contentType.sys.id;
        const itemId = item.sys.id;

        if (itemModel !== "formRelevance") {
          return a;
        }

        // relevance calculation for formRelevance only.
        const answer = {...answers[itemId]};

        const relevance =
          item.fields.onOption === answer.value
            ? item.fields.onRelevance || 0
            : item.fields.offRelevance || 0;

        return a + relevance;
      }, 0);

    // check if a choice was clicked, and if yes, build the answer.
    case "choices":
      const answer = {...answers[stepId]};
      const choiceId = answer.value;

      const stepDefinition = stepDefinitions[choiceId];
      if (!stepDefinition?.fields?.relevance) {
        return 0;
      }

      const relevance = stepDefinition.fields.relevance;

      return relevance;

    default:
      return 0;
  }
}
