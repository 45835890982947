import React, {useContext, useEffect, useState} from "react";

import LinearProgress from "@mui/material/LinearProgress";

import StepProvider, {stepContext} from "../provider/StepProvider";
import AnswerProvider from "../provider/AnswerProvider";
import DebugInquiry from "../components/DebugInquiry";
import InquiryProvider, {inquiryContext} from "../provider/InquiryProvider";
import {withBasicProviders} from "../provider/withBasicProviders";
import CreatorProvider, {creatorContext} from "../provider/CreatorProvider";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Form from "../components/Form";
import StaticAlert from "../components/StaticAlert";

function User(props) {
  const {debug} = props;
  const {steps} = useContext(stepContext);
  const {creator, error: creatorError} = useContext(creatorContext);
  const {submission} = useContext(inquiryContext);

  useEffect(() => {
    if (!creator) {
      return;
    }
    document.title = `fillter.me ${creator.socialname} Anfrage`;
  }, [creator]);

  if (steps.length === 0) {
    return <LinearProgress />;
  }

  if (creatorError) {
    return <StaticAlert action="error" />;
  }

  if (!creator) {
    return <LinearProgress />;
  }

  if (submission) {
    return (
      <>
        <Header />
        <StaticAlert action="created" />
        <Footer />
      </>
    );
  }

  return (
    <>
      {(process.env.NODE_ENV !== "production" || debug) && <DebugInquiry />}
      <Header />
      <Form />
      <Footer />
    </>
  );
}

export default withBasicProviders(
  CreatorProvider,
  StepProvider,
  AnswerProvider,
  InquiryProvider
)(User);
