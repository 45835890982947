import React, {useContext, useEffect, useState, useRef} from "react";
import TextField from "@mui/material/TextField";
import {StyledBox} from "./FormAtoms";

export function FormText(props) {
  const [focus, setFocus] = useState(false);
  const {
    entryId,
    stepId,
    fields,
    answers,
    handleAnswer,
    isQuestionValid,
    stepDefinitions,
  } = props;
  const value = answers[entryId]?.value || "";

  function sanitize(input) {
    if (fields.validation === "email") {
      return input.trim();
    }
    return input;
  }

  // calculate, if email fields of this step are valid.
  const step = stepDefinitions[stepId];
  const mailQuestions = step.fields.questions.filter(
    (q) => q.fields?.validation === "email"
  );
  const mailAnswers = mailQuestions.map((q) => answers[q.sys.id]?.value);
  const stepMailsNotEqual = !mailAnswers.every((q) => q === mailAnswers[0]);

  return (
    <StyledBox>
      <TextField
        onChange={(e) =>
          handleAnswer(
            entryId,
            sanitize(e.target.value),
            fields.title,
            "formText",
            fields?.inquiryCategory?.fields?.title
          )
        }
        onBlur={() => {
          setFocus(false);
          handleAnswer(
            entryId,
            value.trim(),
            fields.title,
            "formText",
            fields?.inquiryCategory?.fields?.title
          );
        }}
        onFocus={() => setFocus(true)}
        required={!!fields.validation && fields.validation !== "urlOptional"}
        name={entryId}
        inputProps={{maxLength: fields.maxLength}}
        helperText={fields.caption}
        value={value}
        error={
          (!focus && //only show erros when focused.
            answers[entryId]?.value && //only show errors, when a value is there.
            !isQuestionValid(
              //when value of the answer is wrong.
              answers[entryId]?.value,
              "formText",
              fields.validation
            )) ||
          (fields.validation === "email" && stepMailsNotEqual) //or when we have an email field and it has not the same value then the other emails
        }
        label={fields.title}
        fullWidth
      />
    </StyledBox>
  );
}
