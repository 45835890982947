export function mainInquiryData(steps, answers, stepDefinitions) {
  return steps
    .map((stepId) => {
      const step = stepDefinitions[stepId];
      const fields = step.fields;
      return Object.keys(fields).map((contentModel) => {
        const entry = fields[contentModel];
        switch (contentModel) {
          case "questions":
            return inquiryQuestion(stepDefinitions, answers, stepId, entry);
          case "choices":
            return inquiryChoice(stepDefinitions, answers, stepId, entry);
        }
      });
    })
    .flat(9)
    .filter((e) => e);
}

function inquiryQuestion(stepDefinitions, answers, stepId, entry) {
  if (entry.length === 0) {
    return;
  }

  return entry
    .filter((item) => item.fields.inInquiry)
    .map((item) => {
      const entryId = item.sys.id;

      if (!answers[entryId]) {
        return null;
      }

      let answer = {...answers[entryId]};
      answer.value = `${answer.value}`;

      return [
        {
          id: entryId,
          type: "question",
          value: answer.title,
          contentModel: answer.contentModel,
          category: answer.category,
        },
        {
          id: entryId,
          type: "answer",
          value: answer.value,
          contentModel: answer.contentModel,
          category: answer.category,
        },
      ];
    })
    .filter((e) => e);
}

function inquiryChoice(stepDefinitions, answers, stepId, entry) {
  let answer = {...answers[stepId]};
  const choiceId = answer.value;

  // step of the current frame.
  if (!stepDefinitions[choiceId]) {
    //not filled out yet?
    return null;
  }
  const stepDefinition = stepDefinitions[choiceId];

  // selected item should not be in the inquiry
  if (!stepDefinition.fields.inquiryType) {
    return null;
  }

  // a choice is either a question or an answer. this is decoded in the inquiryType of the Frame.
  return {
    id: stepId,
    category: stepDefinition.fields.inquiryCategory?.fields?.title,
    type: stepDefinition.fields.inquiryType,
    value: answer.title,
    contentModel: answer.contentModel,
  };
}
