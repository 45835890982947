import React, {useContext, useEffect, useState} from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import styled from "@emotion/styled";

export const StyledBox = styled(Box)`
  margin-bottom: 1rem;
`;

export const StyledButton = styled(Button)`
  margin-bottom: 1rem;
`;

export const StyledLoadingButton = styled(LoadingButton)`
  margin-bottom: 1rem;
`;

export const StyledTitle = styled(Typography)`
  font-weight: bold;
`;

export const StyledDescription = styled.div``;
