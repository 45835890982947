import React, {useContext, useEffect, useState} from "react";

import LinearProgress from "@mui/material/LinearProgress";
import AlertTitle from "@mui/material/AlertTitle";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Typography from "@mui/material/Typography";
import FormHelperText from "@mui/material/FormHelperText";
import Checkbox from "@mui/material/Checkbox";
import Alert from "@mui/material/Alert";
import Zoom from "@mui/material/Zoom";
import LoadingButton from "@mui/lab/LoadingButton";
import CheckIcon from "@mui/icons-material/Check";
import {getPage} from "../contentful";
import {StyledMain} from "../components/CommonAtoms";
import {renderRichText} from "../contentful/render";

function StaticAlert(props) {
  const [content, setContent] = useState(null);
  const [terms, setTerms] = useState(false);

  const {action, onClick, error, loading, result} = props;

  useEffect(() => {
    (async () => {
      const received = await getPage(action);
      setContent(received);
    })();
  }, []);

  if (!content) {
    return <LinearProgress />;
  }

  const hasTerms = content.terms && content.terms.content.length > 0;

  const initial = !result && !error;
  const success = result && !error;

  return (
    <>
      <StyledMain>
        {initial && (
          <Alert variant="outlined" severity={content.severity}>
            <AlertTitle>{content.title}</AlertTitle>
            <Typography component="div" variant="body2" gutterBottom>
              {renderRichText(content.description)}
            </Typography>
            {hasTerms && (
              <FormControl>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={terms}
                        onChange={(e) => setTerms(!terms)}
                      />
                    }
                    label={
                      <Typography component="div" variant="caption">
                        {renderRichText(content.terms)}
                      </Typography>
                    }
                  />
                  <FormHelperText></FormHelperText>
                </FormGroup>
              </FormControl>
            )}
            {content.button && (
              <LoadingButton
                loading={loading}
                disabled={hasTerms && !terms}
                onClick={() => onClick()}
                loadingPosition="start"
                color={content.severity}
                startIcon={<CheckIcon />}
                variant="contained">
                {content.button}
              </LoadingButton>
            )}
          </Alert>
        )}

        {success && (
          <Zoom in={success}>
            <Alert variant="outlined" severity={"success"}>
              <AlertTitle>{content.title}</AlertTitle>
              <Typography component="div" variant="body2">
                {renderRichText(content.success)}
              </Typography>
            </Alert>
          </Zoom>
        )}

        {error && (
          <Zoom in={error}>
            <Alert variant="outlined" severity={"error"}>
              <AlertTitle>{`${error}`}</AlertTitle>
              {result?.split("\n").map((r, i) => (
                <p key={i}>{r}</p>
              ))}
            </Alert>
          </Zoom>
        )}
      </StyledMain>
    </>
  );
}

export default StaticAlert;
