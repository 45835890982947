import React, {useContext, useEffect, useState} from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import {answerContext} from "../provider/AnswerProvider";
import styled from "@emotion/styled";

const StyledBox = styled(Box)`
  position: fixed;
  top: 0;
  z-index: 5;
  right: 0;
  background: ${(props) => props.theme.palette.background.default};
  padding: 10px;
  width: 300px;
  border: 3px dashed ${(props) => props.theme.palette.secondary.light};
  opacity: 0.7;
  > p {IJKt4yhEVHJjypihqDQu
    font-size: 10px !important;
  }
  overflow: scroll;
  max-height: 100vh;
`;

function groupByCategory(inquiry) {
  let grouped = {};
  inquiry.forEach((element) => {
    const category = element.category;
    if (!grouped[category]) {
      grouped[category] = [];
    }
    grouped[category].push(element);
  });

  return grouped;
}

//@todo: rename
function DebugInquiry(props) {
  const {inquiryData, brandData, relevanceData, aliasData} =
    useContext(answerContext);
  const inquiry = inquiryData();
  const brand = brandData();
  const relevance = relevanceData();

  const alias = aliasData();

  const grouped = groupByCategory(inquiry);
  // console.log(inquiry);
  // console.log(grouped);

  return (
    <StyledBox>
      <Typography variant="h6">Relevance: {relevance}</Typography>
      <Typography variant="h6">alias:</Typography>
      {Object.keys(alias).map((k) => (
        <Typography key={k}>
          <b>{k}</b> {alias[k]}
        </Typography>
      ))}{" "}
      <Typography variant="h6">brand:</Typography>
      {Object.keys(brand).map((k) => (
        <Typography key={k}>
          <b>{k}</b> {brand[k]}
        </Typography>
      ))}
      {Object.keys(grouped).map((k) => (
        <div key={k}>
          <br />
          <Typography key={k} variant="h6">
            <i>#{k}</i>
          </Typography>
          {grouped[k].map((item, i) => (
            <Typography key={i}>
              {item.type === "question" && (
                <>
                  <b> {item.value.toString()} </b>
                </>
              )}
              {item.type !== "question" && item.value.toString()}
            </Typography>
          ))}
        </div>
      ))}
    </StyledBox>
  );
}
export default DebugInquiry;
