import React, {useContext, useEffect, useState} from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import FormHelperText from "@mui/material/FormHelperText";
import {StyledBox} from "./FormAtoms";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

export function FormRelevance(props) {
  const {entryId, fields, answers, handleAnswer} = props;

  const checked = answers[entryId]?.value === fields.onOption;
  const currentLabel = checked ? fields.onOption : fields.offOption;
  const otherLabel = !checked ? fields.onOption : fields.offOption;

  // submit on mount the default option
  useEffect(() => {
    // do not, if already checked.
    if (checked) {
      return;
    }
    handleAnswer(entryId, fields.offOption, fields.title, "formRelevance");
  }, []);

  return (
    <StyledBox>
      <FormControl component="fieldset">
        <FormLabel required={true} component="legend">
          {fields.title}
        </FormLabel>

        <FormHelperText>{fields.caption}</FormHelperText>
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                checked={checked}
                onChange={(e) =>
                  handleAnswer(
                    entryId,
                    otherLabel,
                    fields.title,
                    "formRelevance",
                    fields?.inquiryCategory?.fields?.title
                  )
                }
              />
            }
            label={currentLabel}
          />
        </FormGroup>
      </FormControl>
    </StyledBox>
  );
}
