import React, {useContext, useEffect, useState} from "react";
import Typography from "@mui/material/Typography";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormLabel from "@mui/material/FormLabel";
import Checkbox from "@mui/material/Checkbox";
import FormHelperText from "@mui/material/FormHelperText";
import {StyledBox} from "./FormAtoms";

export function FormCheckbox(props) {
  const {entryId, fields, answers, handleAnswer, isQuestionValid} = props;

  const error =
    answers[entryId]?.value &&
    !isQuestionValid(answers[entryId]?.value, "formCheckbox");

  return (
    <StyledBox>
      <FormControl error={error}>
        <FormGroup>
          <FormLabel required={true} component="legend">
            {fields.title}
          </FormLabel>
          <FormControlLabel
            control={
              <Checkbox
                required={true}
                checked={answers[entryId]?.value || false}
                onChange={(e) =>
                  handleAnswer(
                    entryId,
                    e.target.checked,
                    fields.title,
                    "formCheckbox",
                    fields?.inquiryCategory?.fields?.title
                  )
                }
                name={entryId}
              />
            }
            label={
              <Typography color={error ? "error" : "default"}>
                {fields.option}
              </Typography>
            }
          />
          <FormHelperText>{fields.caption}</FormHelperText>
        </FormGroup>
      </FormControl>
    </StyledBox>
  );
}
