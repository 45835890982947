import React, {useState, useEffect, useContext} from "react";
import useFetch from "use-http";
import {useParams} from "react-router";
import {API} from "../config";

function CreatorProvider(props) {
  const [creator, setCreator] = useState(null);

  const {creatorname} = useParams();

  const {get, loading, error} = useFetch(API);

  useEffect(() => {
    (async () => {
      const result = await get(`/creators/${creatorname}`);
      setCreator(result.creator);
    })();
  }, []);

  return (
    <creatorContext.Provider
      value={{
        creator,
        error,
        loading,
      }}>
      {props.children}
    </creatorContext.Provider>
  );
}

export default CreatorProvider;
export const creatorContext = React.createContext();
