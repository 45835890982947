import React, {useState, useEffect, useContext} from "react";
import {getEntry} from "../contentful";

function StepProvider(props) {
  const [steps, setSteps] = useState(
    localStorage.getItem("debug") === "1"
      ? JSON.parse(localStorage.getItem("steps"))
      : []
  );

  const [stepDefinitions, setStepDefinitions] = useState(
    localStorage.getItem("debug") === "1"
      ? JSON.parse(localStorage.getItem("stepDefinitions"))
      : {}
  );
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (steps.length > 0) {
      return;
    }
    nextStep(
      localStorage.getItem("devenv") === "1"
        ? "4udwm30w3ubUqretfN1pq5"
        : `${process.env.CONTENTFUL_PAGE_FORM}`
    );
    setInitialized(true);
  }, []);

  async function nextStep(id) {
    const nextStep = {...stepDefinitions};
    nextStep[id] = await getEntry(id);
    setStepDefinitions(nextStep);
    //debug
    window.stepDefinitions = nextStep;
    const updated = [...steps, id];
    setSteps(updated);
    //debug
    window.steps = updated;
  }

  function prevStep() {
    const prevsteps = [...steps];
    prevsteps.pop();
    setSteps(prevsteps);
    //debug
    window.steps = prevsteps;
  }

  // console.log("steps", steps);
  // console.log("stepDefinitions", stepDefinitions);

  return (
    <stepContext.Provider
      value={{
        steps,
        stepDefinitions,
        initialized,
        nextStep,
        prevStep,
        getEntry,
      }}>
      {props.children}
    </stepContext.Provider>
  );
}

export default StepProvider;
export const stepContext = React.createContext();
