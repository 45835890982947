import React, {useContext, useEffect, useState} from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import FormHelperText from "@mui/material/FormHelperText";
import {StyledBox} from "./FormAtoms";

export function FormRadiobuttons(props) {
  const {entryId, fields, answers, handleAnswer, isQuestionValid} = props;

  const error =
    answers[entryId]?.value &&
    !isQuestionValid(answers[entryId]?.value, "formRadiobuttons");

  return (
    <StyledBox>
      <FormControl component="fieldset" error={error}>
        <FormLabel required={true} component="legend">
          {fields.title}
        </FormLabel>
        <FormHelperText>{fields.caption}</FormHelperText>
        <RadioGroup
          name={entryId}
          value={answers[entryId]?.value || null}
          onChange={(e) =>
            handleAnswer(
              entryId,
              e.target.value,
              fields.title,
              "formRadiobuttons",
              fields?.inquiryCategory?.fields?.title
            )
          }>
          {fields.options.split("\n").map((option) => (
            <FormControlLabel
              key={option}
              value={option}
              control={<Radio />}
              label={option}
            />
          ))}
        </RadioGroup>
      </FormControl>
    </StyledBox>
  );
}
