import Link from "@mui/material/Link";
import React, {useContext, useEffect} from "react";

function Home(props) {
  const creators = [
    "jana",
    "felix",
    "klaus",
    "oguz",
    "isabel",
    "pauline",
    "mirella",
    "dariadaria",
    "marienasemann",
    "oumijanta",
  ];
  useEffect(() => {
    if (process.env.NODE_ENV !== "production") {
      return;
    }
    window.location.href = "https://www.fillter.me/?ref=go.fillter.me";
  });

  if (process.env.NODE_ENV === "production") {
    return null;
  }

  return (
    <>
      <ul>
        {creators.map((creator) => (
          <li key={creator}>
            <Link href={`/${creator}`}>https://go.fillter.me/{creator}</Link>
          </li>
        ))}
      </ul>
      <Link href={`flow`}>https://go.fillter.me/flow</Link>
    </>
  );
}

export default Home;
