import styled from "@emotion/styled";

export const StyledMain = styled.main`
  max-width: 800px;
  min-height: 400px;
  margin: auto;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 4rem;
`;
