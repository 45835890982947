import React, {useContext, useEffect, useState} from "react";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import styled from "@emotion/styled";
import logo from "../../assets/logo-green-white.svg";

const StyledFooterLogo = styled.img`
  height: 2.5em;
`;

const StyledTopFooter = styled.footer`
  background-color: ${(props) => props.theme.palette.primary.main};
`;

const StyledTopFooterWrapper = styled(Box)`
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  max-width: 800px;
  margin: auto;
`;

const StyledBottomFooter = styled.footer`
  background-color: ${(props) => props.theme.palette.primary.dark};
`;

const StyledBottomFooterWrapper = styled(Box)`
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 2rem;
  padding-bottom: 4rem;
  max-width: 800px;
  margin: auto;
`;

function Footer(props) {
  return (
    <>
      <StyledTopFooter>
        <StyledTopFooterWrapper>
          <Grid container>
            <Grid item xs={12} sm={6}>
              <Typography textAlign="center" variant="body2">
                <Link
                  target="_blank"
                  color="primary.contrastText"
                  href="https://www.fillter.me/impressum/">
                  Impressum
                </Link>
              </Typography>
            </Grid>
            {/* <Grid item xs={12} sm={4}>
              <Typography textAlign="center" variant="body2">
                <Link
                  target="_blank"
                  color="primary.contrastText"
                  href="https://www.fillter.me/nutzungsbedingungen/">
                  Nutzungsbedingungen
                </Link>
              </Typography>
            </Grid> */}
            <Grid item xs={12} sm={6}>
              <Typography textAlign="center" variant="body2">
                <Link
                  target="_blank"
                  color="primary.contrastText"
                  href="https://www.fillter.me/datenschutzerklaerung/">
                  Datenschutz
                </Link>
              </Typography>
            </Grid>
          </Grid>
        </StyledTopFooterWrapper>
      </StyledTopFooter>
      <StyledBottomFooter>
        <StyledBottomFooterWrapper>
          <Grid container>
            <Grid item xs={12} sm={6}>
              <Typography>
                <StyledFooterLogo src={logo} />
              </Typography>
              <Typography gutterBottom variant="body2">
                <b>So funktionieren Anfragen heute.</b>
              </Typography>
              <Typography gutterBottom variant="body2">
                &copy; {new Date().getFullYear()}{" "}
                <Link
                  target="_blank"
                  color="secondary"
                  href="https://yilmazhummel.com/">
                  YilmazHummel
                </Link>{" "}
                &amp;{" "}
                <Link target="_blank" color="secondary" href="https://v01.io">
                  Breyer
                </Link>
              </Typography>
              <Typography gutterBottom variant="body2">
                YilmazHummel GbR, Mühlenstraße 8A, 14167 Berlin
              </Typography>
            </Grid>
          </Grid>
        </StyledBottomFooterWrapper>
      </StyledBottomFooter>
    </>
  );
}

export default Footer;
